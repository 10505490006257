import * as React from 'react';

import { v4 as uuidv4 } from 'uuid';

import {InstancePropertyFormWidget} from 'kiini/Forms';
import ObjectListWithPanel from 'kiini/ObjectListWithPanel';

import {contentRoleFormConfigurations} from './configuration';

import { 
    NotPublic, 
    PlayProperties, 
    TrailProperties, 
    TextProperties,
    ClosingsPeriods,
    OpenPeriods,
    EatDrinkFacilities,
    DogWalkingTrail,
    Quest,
    Environment,
    SpecialDetails,
    TriggerObjectsInOrder
} from 'model/contentObjects';

const advancedRolesClazzNames = [
    'content_objects.roles.TrailProperties',
    'content_objects.roles.SpecialDetails',
    'content_objects.roles.CopyRights',
    'content_objects.roles.NotPublic',
    'content_objects.roles.EatDrinkFacilities',
    'content_objects.roles.PlayProperties',
    'content_objects.roles.TextProperties',
    'content_objects.roles.ClosingsPeriods',
    'content_objects.roles.OpenPeriods',
    'content_objects.roles.DogWalkingTrail',
    'content_objects.roles.Quest',
    'content_objects.roles.Environment',
    'content_objects.roles.TriggerObjectsInOrder',
]; 

const advancedRolesCreationOptions = {
    
    'content_objects.roles.TrailProperties': {
        label: "Trail Properties", 
        creator: () => (new TrailProperties(null, {id: uuidv4()}))
    },
    'content_objects.roles.NotPublic': {
        label: "NotPublic", 
        creator: () => (new NotPublic(null,{id: uuidv4()}))
    },    
    'content_objects.roles.Quest': {
        label: "Quest", 
        creator: () => (new Quest(null,{id: uuidv4()}))
    },
    'content_objects.roles.PlayProperties': {
        label: "Game play", 
        creator: () => (new PlayProperties(null,{id: uuidv4()}))
    },    
    'content_objects.roles.TextProperties': {
        label: "Text Properties", 
        creator: () => (new TextProperties(null,{id: uuidv4(), values:[]}))
    }, 
    'content_objects.roles.SpecialDetails': {
        label: "Special Details", 
        creator: () => (new SpecialDetails(null,{id: uuidv4(), values:[]}))
    },
    'content_objects.roles.ClosingsPeriods': {
        label: "Closings Periods", 
        creator: () => (new ClosingsPeriods(null,{id: uuidv4(), values:[]}))
    },    
    'content_objects.roles.OpenPeriods': {
        label: "Open Periods", 
        creator: () => (new OpenPeriods(null,{id: uuidv4(), values:[]}))
    },    
    'content_objects.roles.EatDrinkFacilities': {
        label: "Eat/drink facilities on trail", 
        creator: () => (new EatDrinkFacilities(null,{id: uuidv4(), values:[]}))
    },    
    'content_objects.roles.DogWalkingTrail':{
        label: "Dog walking trail",
        creator: () => (new DogWalkingTrail(null, {id: uuidv4()}))
    },
    'content_objects.roles.Environment': {
        label: "Environment", 
        creator: () => (new Environment(null, {id: uuidv4(), environmentTypes:[]}))
    },
    'content_objects.roles.TriggerObjectsInOrder': {
        label: "Trigger objects in order", 
        creator: () => (new TriggerObjectsInOrder(null, {id: uuidv4()}))
    }
}


const ExperienceRolesForm = ({instance}) => {
    return <InstancePropertyFormWidget 
    instance={instance} 
    fieldName="contentRoles"
    label="Properties"
    Widget={ObjectListWithPanel}
    widgetProps={{
        creationOptions: advancedRolesCreationOptions,
        itemFilterFuction: instance =>  advancedRolesClazzNames.some( name => name === instance.$classDescription.clazzName),
        oneInstancePerClassInList: true,
    }}
    formConfigurations={contentRoleFormConfigurations}/>
}


export default ExperienceRolesForm;